/*! -----------------------------------------------------------------------------------

    Template Name: Cuba Admin
    Template URI: http://admin.pixelstrap.com/cuba/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
 */
// *** utils ***
@import "utils/variables";

:root {
  --theme-deafult: #7366ff;
  --theme-secondary: #f73164;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2f2f3b;
  --chart-border: #ecf3fa;
  --recent-chart-bg: #fcfcfd;
  --light-bg: #f6f7f9;
  --white: #fff;
  --light2: #f5f6f9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-dashed-border: rgba(82, 82, 108, 0.3);
  --chart-dashed-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #f2f4f7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #f2f3f7;
  --course-bg: #f7f7f7;
  --balance-profie-bg: #e0dfef;
  --view-grid-bg: #ecf3fa80;
  --view-border-marker: #cfcdfc;

  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
}

body[class="dark-only"] {
  --light-background: rgba(31, 32, 40, 0.65);
  --recent-chart-bg: #262932;
  --light-bg: #1d1e26;
  --white: #262932;
  --sidebar-border: rgba(255, 255, 255, 0.1);
  --light2: #1d1e26;
  --chart-border: #374558;
  --recent-box-bg: #1d1e26;
  --recent-border: #374558;
  --body-font-color: rgba(255, 255, 255, 0.6);
  --course-light-btn: var(--light-bg);
  --view-grid-bg: var(--light-bg);
  --view-border-marker: #2c2c45d4;
}

// *** base ***
@import "base/reset";
@import "base/typography";

//  *** components ***
@import "components/according";
@import "components/alert";
@import "components/avatars";
@import "components/badge";
@import "components/bookmark";
@import "components/breadcrumb";
@import "components/builders";

@import "components/buttons";

@import "components/card";
// @import "components/color";
@import "components/datatable";
@import "components/datepicker";
@import "components/dropdown";
@import "components/form-builder";
@import "components/form-input";
@import "components/form-wizard";
@import "components/forms";
@import "components/form_builder-2";
@import "components/icons";
@import "components/list";
@import "components/loader";
@import "components/popover";
@import "components/print";
@import "components/radio";
@import "components/ribbon";
@import "components/switch";
@import "components/tab";
@import "components/table";
@import "components/touchspin";
@import "components/tour";
@import "components/tree";
@import "components/typeahead-search";
@import "components/scrollbar";

//	*** pages ***

@import "pages/blog";
@import "pages/bookmark-app";
@import "pages/cart";
@import "pages/chart";
@import "pages/chat";
@import "pages/checkout";
@import "pages/comingsoon";
@import "pages/contacts";
@import "pages/dashboard_2";
@import "pages/dashboard_3";
@import "pages/dashboard_4";
@import "pages/dashboard_5";
@import "pages/dashboard_default";
@import "pages/ecommerce";
@import "pages/email-application";
@import "pages/errorpage";
@import "pages/faq";
@import "pages/file";
@import "pages/gallery";
@import "pages/internationalization";
@import "pages/job-search";
@import "pages/jsgrid";
@import "pages/kanban";
@import "pages/knowledgebase";
@import "pages/landing";
@import "pages/language";
@import "pages/learning";
@import "pages/login";
@import "pages/megaoption";
@import "pages/order-history";
@import "pages/page";
@import "pages/pricing";
@import "pages/progress";
@import "pages/projectlist";
@import "pages/social-app";
@import "pages/task";
@import "pages/timeline-v";
@import "pages/timeliny";
@import "pages/user-profile";
@import "pages/wishlist";
@import "pages/kanban";
@import "vendors/sticky.scss";
@import "vendors/todo.scss";

/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark";
@import "themes/theme-customizer";
@import "themes/update";
// *** layout ***

@import "layout/footer";
@import "layout/grid";
@import "layout/header";
@import "layout/navs";
@import "layout/search";
@import "layout/select2";
@import "layout/sidebar";
@import "layout/rtl";
@import "layout/box-layout";

// custom css
@font-face {
  /* font-family: "Gilroy-Medium";
  src: url("./fonts/Gilroy-Medium.ttf") format("ttf"); */
  font-family: "Kalpurush";
  src: url("../fonts/nikosh/Kalpurush.ttf") format("ttf");
}
body {
  font-family: "Kalpurush" !important;
  overflow-y: scroll !important;
}
#form-card a,
#form-card .link {
  text-decoration: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.add-report {
  background-color: #333339 !important;
  color: white !important;
}

.sidebar-content-sub {
  height: 98vh !important;
  overflow-y: scroll !important;
  padding-bottom: 300px !important;
}
a {
  text-decoration: none !important;
}

/* this section used for print table custom css modification */
/* custom scrollbar  */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: 5px;
  scrollbar-color: white;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(38, 50, 56, 0.2);
  border-radius: 10px;
}
*::-webkit-scrollbar:horizontal {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(38, 50, 56, 0.2);
  border-radius: 10px;
}
*::-webkit-scrollbar-track:horizontal {
  background: transparent;
}
::-webkit-scrollbar-thumb:hover {
  background-color: gray;
}

#print .table-bordered thead,
.table-bordered tbody,
.table-bordered tfoot,
.table-bordered tr,
.table-bordered td,
.table-bordered th {
  border-color: #000 !important;
}
#print .table > :not(caption) > * > * {
  padding: 0.2rem 0.2rem !important;
}

thead,
th {
  background-color: rgb(75, 71, 71) !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: white !important;
}

#print-table th {
  background-color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: white !important;
}

#role .check-box-css {
  display: inline-block !important;
  width: 300px !important;
}
#print-table .fixed-size {
  width: 100px !important;
  color: black !important;
  font-style: normal;
  font-weight: 200 !important;
}
#print-table .question-title {
  width: 350px !important;
  margin: 0 !important;
  padding: 0 !important;
}
#detail .question-title {
  width: 600px !important;
  // margin: 0 !important;
  // padding: 0 !important;
}

#print-table .comment {
  width: 250px !important;
  color: black !important;
  font-style: normal;
  font-weight: 200 !important;
}
#print-table .extra {
  width: 50px !important;
}
.ring {
  position: relative;
  // top: 25px !important;
  // left: -25px;
  left: 28px !important;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 4 !important;
}
